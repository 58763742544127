import { useState, useEffect, useRef } from "react";
import { useUser } from "../../context/UserProvider";
import http from "../../utils/http.utils";
import Modal from "../Modal";
import ModalHeader from "./ModalHeader";
import playIcon from "../../assets/icons/picto-play.svg";

export default function ModalFirstLogin() {
  const { user, persistUser } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [popup, setPopup] = useState(null);
  const [showIcon, setShowIcon] = useState(true);
  const videoRef = useRef(null);

  const getAttribute = (attributeName) => {
    return popup?.values[attributeName]?.[0]?.data;
  };

  const getPopup = async () => {
    try {
      const result = await http.get("/api/flat-popups");
      const popup = result["hydra:member"]?.[0];
      setPopup(popup);

      if (
        !user?.view?.includes(popup?.values?.attribute_popup_link?.[0]?.data) &&
        popup?.values?.attribute_popup_link?.[0]?.data
      ) {
        setIsOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPopup();
  }, []);

  const pushView = async (newView) => {
    const views = [...user.view, newView];
    try {
      const result = await http.post("/api/user-views", {
        view: views,
      });
      await persistUser(result);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    pushView(getAttribute("attribute_popup_link"));
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="flex flex-col w-[90dvw]  md:w-[70dvw]">
        <ModalHeader title={getAttribute("content_title")} onClose={handleClose} />
        <div className="bg-white p-2 flex flex-col gap-2 h-full">
          <div
            className="text-justify"
            dangerouslySetInnerHTML={{
              __html: getAttribute("content_description"),
            }}
          />

          {getAttribute("attribute_popup_link") ? (
            <div className="relative w-full">
              {showIcon ? (
                <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer z-10">
                  <img
                    src={playIcon}
                    alt="play"
                    width={70}
                    onClick={() => videoRef.current.play()}
                  />
                </span>
              ) : null}
              <video
                width={"100%"}
                ref={videoRef}
                onPause={() => setShowIcon(true)}
                onPlay={() => setShowIcon(false)}
                controls
              >
                <source src={getAttribute("attribute_popup_link")} type="video/mp4" />
              </video>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
