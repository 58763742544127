import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import translateKey from "../../utils/translateKey";

const Notification = ({ notification, index }) => {
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [canBeOpened, setCanBeOpened] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (contentRef.current) {
      setCanBeOpened(
        contentRef.current.offsetWidth < contentRef.current.scrollWidth
      );
    }
  }, [contentRef]);

  const handleClick = async (e) => {
    if (canBeOpened) {
      setIsOpen(!isOpen);
    }
  };

  const notificationContent = (
    <div
      onClick={handleClick}
      className={`text-gray-700 p-4 text-sm cursor-pointer hover:!bg-primary-light whitespace-nowrap ${
        index % 2 === 0 ? "bg-primary-100" : ""
      }`}
    >
      <div className="font-bold overflow-hidden">
        {translateKey(notification.title)}
      </div>
      <p
        ref={contentRef}
        id={`notification-${notification.id}`}
        className={isOpen ? "whitespace-break-spaces" : "truncate"}
        dangerouslySetInnerHTML={{
          __html: translateKey(notification.content),
        }}
      />
      {canBeOpened && (
        <span className="text-xs text-gray-500">
          {isOpen ? t("showLess") : t("showMore")}
        </span>
      )}
    </div>
  );

  if (notification.attributeNotificationLink) {
    return (
      <a
        href={notification.attributeNotificationLink}
        ref={containerRef}
        target="_blank"
        rel="noreferrer"
      >
        {notificationContent}
      </a>
    );
  }

  return notificationContent;
};

export default Notification;
