import http from "../utils/http.utils";
import { isDesktop } from "../utils/style.utils";
import { getChildrens } from "../utils/tree.utils";
import Breadcrumbs from "../components/Breadcrumbs";
import Header from "../components/ContentCategory/Header";
import Loader from "../components/Loader";
import MediaModal from "../components/MediaModal/MediaModal";
import Navbar from "../components/Navbar/Navbar";
import ContentsView from "../components/Views/ContentsView";
import GridContentsView from "../components/Views/GridContentsView";
import NavGridView from "../components/Views/NavGridView";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import translateKey from "../utils/translateKey";
import ButtonTutorial from "../components/ButtonTutorial";
import ButtonFilesCategories from "../components/ButtonFilesCategories";

export default function ContentCategoryPage() {
  const navigate = useNavigate();
  const locale = useLocale();

  const [categories, setCategories] = useState(null);
  const [category, setCategory] = useState(null);
  const [nav, setNav] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(false);
  const [leaf, setLeaf] = useState(null);

  const [isLoading, setIsLoading] = useState({
    categories: true,
    nav: true,
    leaf: true,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = useParams();

  const categoryId = searchParams.get("category");

  const fetchNav = async () => {
    setIsLoading((prev) => ({ ...prev, nav: true }));
    const nav = await http.get(`/api/flat-content-categories/${params.nav}`);
    setNav(nav);
    setIsLoading((prev) => ({ ...prev, nav: false }));

    setIsLoading((prev) => ({ ...prev, categories: true }));
    const categories = await http.get("/api/flat-content-categories", {
      pagination: false,
      breadcrumb: `${nav.breadcrumb.trail}`,
      "breadcrumbDepth[lte]": 0,
    });
    setCategories(categories["hydra:member"]);
    setIsLoading((prev) => ({ ...prev, categories: false }));
  };

  const fetchLeaf = async (id) => {
    setIsLoading((prev) => ({ ...prev, leaf: true }));
    const data = await http.get(`/api/leaves/${id}`);
    setLeaf(data);
    setCategory(data.category);
    setIsLoading((prev) => ({ ...prev, leaf: false }));
  };

  useEffect(() => {
    fetchNav();
  }, [params.nav]);

  useEffect(() => {
    if (!categoryId && categories?.length > 0) {
      const firstParent = categories.find(
        (category) => nav && nav["@id"] === category.parent
      );

      if (getChildrens(firstParent.id, categories)?.length > 0) {
        const id = getChildrens(firstParent.id, categories)[0].id;
        navigate(`/${locale}/nav/${params.nav}?category=${id}`);
      } else {
        navigate(`/${locale}/nav/${params.nav}?category=${firstParent.id}`);
      }
    }
  }, [categories]);

  useEffect(() => {
    setView(null);
    if (categoryId) {
      fetchLeaf(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (leaf) {
      getView(leaf);
    }
  }, [leaf]);

  const [view, setView] = useState(null);

  const enumViewType = {
    GRID: "GRID",
    CONTENTS: "CONTENTS",
    CONTENT_CHILDREN: "CONTENT_CHILDREN",
    NAVIGATE_CHILDREN: "NAVIGATE_CHILDREN",
    GRID_CONTENTS: "GRID_CONTENTS",
    GRID_CONTENTS_GROUPED: "GRID_CONTENTS_GROUPED",
  };

  const getView = (leaf) => {
    // Vue groupé des contenus de type template_4
    if (leaf.children.some((c) => c.contents?.length > 0)) {
      if (leaf.children[0].contents[0]?.attributeGroup === "template_4") {
        setView(enumViewType.GRID_CONTENTS_GROUPED);
        return;
      }
    }
    
    if (leaf.contents?.length > 0) {
      if (leaf.contents[0].attributeGroup === "template_4") {
        setView(enumViewType.GRID_CONTENTS);
      } else {
        setView(enumViewType.CONTENTS);
      }
    } else if (leaf.children?.length > 0) {
      if (!isDesktop()) {
        setView(enumViewType.NAVIGATE_CHILDREN);
      } else {
        setView(enumViewType.CONTENT_CHILDREN);
        if (leaf.children[0].children?.length > 0) {
          setView(enumViewType.NAVIGATE_CHILDREN);
        } else {
          setView(enumViewType.CONTENT_CHILDREN);
        }
      }
    }
  };

  const handleNavBack = () => {
    navigate(`/${locale}/?nav=${nav.parent.split("/").pop()}`);
  };

  const breads = leaf?.category?.breadcrumb.parts.filter(
    (b) =>
      b.id !== +process.env.REACT_APP_CX_ROOT_ID &&
      b.id !== +process.env.REACT_APP_CX_ID
  );

  const items =
    view === enumViewType.CONTENTS
      ? [{ contents: leaf.contents }]
      : view === enumViewType.GRID_CONTENTS
      ? leaf.contents
      : view === enumViewType.CONTENT_CHILDREN
      ? leaf?.children?.map((child) => ({
          contents: child.contents,
          title: translateKey(child.category.names),
        }))
      : leaf?.children?.map((c) => ({
          ...c,
          name:
            c.category.name ||
            translateKey(c.category.names) ||
            c.values.category_name[0].data,
          goTo: `/${locale}/nav/${params.nav}?category=${c.category.id}`,
        }));

  return (
    <div className="h-full flex flex-col">
      {!categories || !nav ? (
        <div className="h-full flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div id="header-container">
          <Header title={translateKey(nav.names)} handleBack={handleNavBack} />
          <Navbar nav={nav} items={categories} route="nav" />
          <div className="px-page py-2 flex flex-col md:flex-row md:justify-between md:items-end">
            <div className="mb-2">
              {breads ? <Breadcrumbs items={breads} showTitle /> : null}
            </div>
            <div className="flex gap-2 items-center">
              {category?.values?.category_link?.[0].data && (
                <ButtonTutorial
                  href={category?.values?.category_link?.[0].data}
                  icon={category?.values?.category_link_icon?.[0].data?.url}
                  label={category?.values?.category_link_name?.[0].data}
                />
              )}
              <ButtonFilesCategories category={category} />
            </div>
          </div>
        </div>
      )}
      <div className="grow overflow-auto z-[1]">
        {!isLoading.leaf && view && (
          <>
            {view === enumViewType.CONTENTS && (
              <ContentsView
                items={[{ contents: leaf.contents }]}
                onClickItem={setCurrentIndex}
              />
            )}
            {view === enumViewType.CONTENT_CHILDREN && (
              <ContentsView
                items={leaf.children.map((child) => ({
                  contents: child.contents,
                  title: translateKey(child.category.names),
                }))}
                onClickItem={setCurrentIndex}
              />
            )}
            {view === enumViewType.GRID_CONTENTS && (
              <GridContentsView contents={leaf.contents} />
            )}
            {view === enumViewType.GRID_CONTENTS_GROUPED &&
              leaf.children.map((child) => (
                <>
                  <div key={child.id}>
                    <h2 className="text-2xl font-lacosteExpandedBold text-primary p-2">
                      {translateKey(child.category.names)}
                    </h2>
                    <GridContentsView contents={child.contents} />
                  </div>
                  <hr />
                </>
              ))}
            {view === enumViewType.NAVIGATE_CHILDREN && (
              <NavGridView
                navs={leaf.children.map((c) => ({
                  ...c,
                  name:
                    c.category.name ||
                    translateKey(c.category.names) ||
                    c.values.category_name[0].data,
                  goTo: `/${locale}/nav/${params.nav}?category=${c.category.id}`,
                }))}
              />
            )}
          </>
        )}
      </div>
      {currentIndex !== false ? (
        <MediaModal
          currentIdx={currentIndex}
          items={items}
          isOpen={false !== currentIndex}
          onClose={() => setCurrentIndex(false)}
        />
      ) : null}
    </div>
  );
}
